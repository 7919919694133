<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :fullscreen="fullscreen"
      scrollable
      max-width="640px"
    >
      <v-card>
        <v-card-title class="flex-nowrap gap-2 justify-space-between mb-4">
          <h3>{{ title }}</h3>
          <v-btn icon @click.stop="dialog = false" class="mb-auto mr-n4">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <slot></slot>
        </v-card-text>

        <v-card-actions
          :class="btnCentered ? 'justify-center' : 'justify-start'"
        >
          <button class="mdButton" :disabled="btnDisabled" @click="emitClick">
            {{ btnText }}
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "CustomDialog",
  props: [
    "title",
    "btnText",
    "btnDisabled",
    "btnCentered",
    "fullscreen",
    "value",
  ],
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog .v-card {
  .v-card__title {
    word-break: normal;
    padding: 16px 24px;
    border-bottom: 1px solid #c8c9cb;
    @media (min-width: 600px) {
      border: none;
    }
  }
  .v-card__text {
    padding-bottom: 0px;
  }
  .v-card__actions {
    margin-top: 16px;
    padding: 10px 24px 18px;
    border-top: 1px solid #c8c9cb;
  }
}
</style>
