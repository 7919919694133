<template>
  <div class="box-image-text">
    <v-row justify="center" class="row-gap-13">
      <v-col
        v-for="(boxItem, i) in boxContent"
        :key="i"
        cols="12"
        :md="dynamicCols"
        sm="6"
      >
        <div
          class="box-content-wrapper"
          :class="$vuetify.breakpoint.xs ? 'mobile' : ''"
        >
          <img
            class="img-fluid mb-3"
            :width="imgWidth"
            :height="imgHeight"
            :src="
              getImgURL(
                $vuetify.breakpoint.xs ? boxItem.srcMobile : boxItem.src
              )
            "
            :alt="boxItem.alt"
            loading="lazy"
          />
          <!-- header-->
          <h2 class="mb-4" v-html="boxItem.title"></h2>

          <!-- text-->
          <p v-if="boxItem.text" v-html="boxItem.text" class="mb-0"></p>

          <!-- list-->
          <ul
            v-if="boxItem.list"
            class="mt-9 mb-4 pr-3"
            :class="$vuetify.breakpoint.xs ? 'pl-8' : ''"
          >
            <li
              v-for="(listItem, i) in boxItem.list"
              :key="i"
              v-html="listItem"
            ></li>
          </ul>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "BoxesImageText",
  props: ["boxContent", "imgWidth", "imgHeight"],
  data: () => ({}),
  methods: {
    getImgURL(path) {
      return process.env.VUE_APP_ASSETS_URL + 'images/' + path;
    },
  },
  computed: {
    dynamicCols() {
      if (this.boxContent.length <= 2) {
        return 6;
      } else if (this.boxContent.length <= 3) {
        return 4;
      } else {
        return 3;
      }
    },
  },
};
</script>
