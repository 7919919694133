<template>
  <v-container class="main-container" v-if="Object.keys(JSONcontent).length">
    <!-- stage component -->
    <div class="full-width-background-wrap pt-7 pb-2">
      <v-container class="inner-content">
        <v-row>
          <v-col cols="12" md="7">
            <h1 class="text-left mb-6">
              {{ JSONcontent.stageComponent.title }}
            </h1>
            <p class="mb-4 mb-md-2">
              {{ JSONcontent.stageComponent.text }}
            </p>
            <SearchComponent
              id="search"
              :placeholder="JSONcontent.stageComponent.searchPlaceholder"
              :errorText="JSONcontent.stageComponent.searchError"
              ref="search"
            />
            <img
              v-if="$vuetify.breakpoint.smAndDown"
              class="img-fluid mb-5 d-flex mx-auto"
              :width="JSONcontent.stageComponent.teaserImg.widthMobile"
              :height="JSONcontent.stageComponent.teaserImg.heightMobile"
              :src="getImgURL(JSONcontent.stageComponent.teaserImg.srcMobile)"
              :alt="JSONcontent.stageComponent.teaserImg.alt"
              :preload="true"
            />
            <div class="d-flex flex-column justify-center justify-md-start">
              <h3 class="mb-3 text-left">
                {{ JSONcontent.stageComponent.subTitle }}
              </h3>
              <p class="text-left mr-auto mb-3">
                {{ JSONcontent.stageComponent.subText }}
              </p>
              <v-btn
                text
                :ripple="false"
                class="scroll-down pa-0 mr-auto"
                color="black"
                @click="scrollDown"
              >
                {{ JSONcontent.stageComponent.linkText }}
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="5"
            class="d-flex"
            no-gutters
            v-if="!$vuetify.breakpoint.smAndDown"
          >
            <img
              class="header-teaser-img img-fluid ma-auto"
              :width="JSONcontent.stageComponent.teaserImg.width"
              :height="JSONcontent.stageComponent.teaserImg.height"
              :src="getImgURL(JSONcontent.stageComponent.teaserImg.src)"
              :alt="JSONcontent.stageComponent.teaserImg.alt"
              :preload="true"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="content pt-0 pt-md-12" ref="content">
      <!-- suppliers component -->
      <v-row no-gutters class="order-2 order-md-1">
        <v-col cols="12" align="center" class="mb-15 mb-md-25">
          <h2 class="mb-3 mb-sm-6">
            {{ JSONcontent.suppliersComponent.title }}
          </h2>
          <div class="d-flex justify-center gap-4 gap-xs-6">
            <img
              v-for="(supplier, i) in JSONcontent.suppliersComponent.suppliers"
              :key="i"
              class="delivery-service-logo"
              :src="getImgURL(supplier.src)"
              :alt="supplier.alt"
              :width="$vuetify.breakpoint.xs ? JSONcontent.suppliersComponent.suppliersImgWidthMobile : JSONcontent.suppliersComponent.suppliersImgWidth"
              :height="$vuetify.breakpoint.xs ? JSONcontent.suppliersComponent.suppliersImgHeightMobile : JSONcontent.suppliersComponent.suppliersImgHeight"
              loading="lazy"
            />
          </div>
        </v-col>
      </v-row>

      <!-- campaign component -->
      <v-row no-gutters class="order-1 order-md-2">
        <div
          class="mb-15 mb-md-25"
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'full-width-background-wrap bg-campaign'
              : ''
          "
        >
          <v-container class="inner-content">
            <v-row no-gutters>
              <v-col cols="12" align="center">
                <CampaignTeaser
                  :imgSrc="
                    getImgURL(
                      $vuetify.breakpoint.smAndDown
                        ? JSONcontent.campaignComponent.teaserImg.srcMobile
                        : JSONcontent.campaignComponent.teaserImg.src
                    )
                  "
                  :imgWidth="
                    $vuetify.breakpoint.smAndDown
                      ? JSONcontent.campaignComponent.teaserImg.widthMobile
                      : JSONcontent.campaignComponent.teaserImg.width
                  "
                  :imgHeight="
                    $vuetify.breakpoint.xs
                      ? JSONcontent.campaignComponent.teaserImg.heightMobile
                      : JSONcontent.campaignComponent.teaserImg.height
                  "
                  :campaignLogo="JSONcontent.campaignComponent.logo"
                  :withContentBox="true"
                  :alt="JSONcontent.campaignComponent.teaserImg.alt"
                  :noBorderOnMobile="true"
                  :headline="JSONcontent.campaignComponent.title"
                  :teaserText="JSONcontent.campaignComponent.subTitle"
                  :text="JSONcontent.campaignComponent.text"
                  :btnText="JSONcontent.campaignComponent.btnText"
                  :btnUrl="JSONcontent.campaignComponent.btnUrl"
                  :bgColor="
                    $vuetify.breakpoint.smAndDown
                      ? JSONcontent.campaignComponent.bgColor
                      : '#FFFFFF'
                  "
                  :textColor="
                    $vuetify.breakpoint.smAndDown ? '#FFFFFF' : '#292929'
                  "
                />
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-row>

      <!-- teaser component -->
      <v-row no-gutters class="order-3">
        <v-col cols="12" align="center" class="mb-15 mb-md-20">
          <h2 class="mb-6 mb-md-12">{{ JSONcontent.teaserComponent.title }}</h2>
          <p class="mb-0">{{ JSONcontent.teaserComponent.text }}</p>
        </v-col>
        <v-col cols="12" class="mb-15 mb-md-25">
          <BoxesImageText
            :boxContent="JSONcontent.teaserComponent.teasers"
            :imgWidth="
              $vuetify.breakpoint.xs
                ? JSONcontent.teaserComponent.teasersImgWidthMobile
                : JSONcontent.teaserComponent.teasersImgWidth
            "
            :imgHeight="
              $vuetify.breakpoint.xs
                ? JSONcontent.teaserComponent.teasersImgHeightMobile
                : JSONcontent.teaserComponent.teasersImgHeight
            "
          />
        </v-col>
      </v-row>

      <!-- steps component -->
      <v-row no-gutters class="order-5 order-md-4" ref="steps">
        <div class="full-width-background-wrap bg-steps py-2 py-md-9 mb-15 mb-md-25">
          <v-container class="inner-content">
            <v-row no-gutters>
              <v-col cols="12" align="center">
                <h2 class="mb-3">{{ JSONcontent.stepsComponent.title }}</h2>
                <p class="mb-5">{{ JSONcontent.stepsComponent.text }}</p>
                <StepsImageText
                  :stepContent="JSONcontent.stepsComponent.steps"
                  :imgWidth="
                    $vuetify.breakpoint.xs
                      ? JSONcontent.stepsComponent.stepsImgWidthMobile
                      : JSONcontent.stepsComponent.stepImgWidth
                  "
                  :imgHeight="
                    $vuetify.breakpoint.xs
                      ? JSONcontent.stepsComponent.stepsImgHeightMobile
                      : JSONcontent.stepsComponent.stepImgHeight
                  "
                />
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-row>

      <!-- apply teaser component
      <v-row no-gutters class="order-4 order-md-5">
        <v-col cols="12" align="center" class="mb-15 mb-md-25">
          <ImageAndTextRow
            :title="JSONcontent.applyTeaserComponent.title"
            :text="JSONcontent.applyTeaserComponent.text"
            :btnText="JSONcontent.applyTeaserComponent.btnText"
            :btnUrl="JSONcontent.applyTeaserComponent.btnUrl"
            :imgURL="
              getImgURL(
                $vuetify.breakpoint.xs
                  ? JSONcontent.applyTeaserComponent.teaserImg.srcMobile
                  : JSONcontent.applyTeaserComponent.teaserImg.src
              )
            "
            :imgWidth="
              $vuetify.breakpoint.xs
                ? JSONcontent.applyTeaserComponent.teaserImg.widthMobile
                : JSONcontent.applyTeaserComponent.teaserImg.width
            "
            :imgHeight="
              $vuetify.breakpoint.xs
                ? JSONcontent.applyTeaserComponent.teaserImg.heightMobile
                : JSONcontent.applyTeaserComponent.teaserImg.height
            "
            :alt="JSONcontent.applyTeaserComponent.teaserImg.alt"
          />
        </v-col>
      </v-row> -->

      <!-- faq component -->
      <v-row no-gutters class="order-7">
        <v-col cols="12" class="mb-11 py-4">
          <h2>{{ JSONcontent.faqComponent.title }}</h2>
        </v-col>
        <AccordionComponent
          :accordion-content="JSONcontent.faqComponent.faqs"
          class="mb-15 mb-md-25"
        />
      </v-row>
    </div>
  </v-container>
</template>

<script>
import SearchComponent from "@/components/SearchComponent";
import BoxesImageText from "@/components/BoxesImageText";
import StepsImageText from "@/components/StepsImageText.vue";
import AccordionComponent from "@/components/AccordionComponent";
// import ImageAndTextRow from "@/components/ImageAndTextRow";
import CampaignTeaser from "@/components/CampaignTeaser.vue";

export default {
  name: "MainPage",
  components: {
    SearchComponent,
    BoxesImageText,
    StepsImageText,
    CampaignTeaser,
    AccordionComponent,
    // ImageAndTextRow,
  },
  data: (_this) => ({
    JSONcontent: _this.$root.JSONcontent ?? {},
    jsonLdData: [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "McDelivery® | McDonald’s Lieferservice",
        url: "https://mcdelivery.de/",
        logo: "https://mcdelivery.de/SocialShareImg-default.png",
      },
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [],
      },
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        image: [],
      },
    ],
  }),
  mounted() {
    this.scrollToTop();
    if (Object.keys(this.JSONcontent).length) {
      this.addJsonLdToHead();
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    scrollDown() {
      this.$refs["steps"].scrollIntoView({ behavior: "smooth" });
    },
    printCurrentYear() {
      return new Date().getFullYear();
    },
    getImgURL(path) {
      return process.env.VUE_APP_ASSETS_URL + 'images/' + path;
    },
    getFAQSchema() {
      let mainEntities = [];
      this.JSONcontent.faqComponent.faqs?.forEach((faq) => {
        let entity = {
          "@type": "Question",
          name: faq.header,
          acceptedAnswer: {
            "@type": "Answer",
            text: faq.content,
          },
        };
        mainEntities.push(entity);
      });
      this.jsonLdData[1].mainEntity = mainEntities;
    },
    getImgSchema() {
      let images = [];
      images.push({
        "@type": "ImageObject",
        contentUrl: this.getImgURL(
          this.JSONcontent.stageComponent.teaserImg.src
        ),
        width: this.JSONcontent.stageComponent.teaserImg.width,
        height: this.JSONcontent.stageComponent.teaserImg.height,
      });
      images.push({
        "@type": "ImageObject",
        contentUrl: this.getImgURL(
          this.JSONcontent.campaignComponent.teaserImg.src
        ),
        width: this.JSONcontent.campaignComponent.teaserImg.width,
        height: this.JSONcontent.campaignComponent.teaserImg.height,
      });
      this.JSONcontent.stepsComponent.steps?.forEach((step) => {
        let image = {
          "@type": "ImageObject",
          contentUrl: this.getImgURL(step.src),
          width: this.JSONcontent.stepsComponent.stepsImgWidth,
          height: this.JSONcontent.stepsComponent.stepsImgHeight,
        };
        images.push(image);
      });
      this.JSONcontent.teaserComponent.teasers?.forEach((teaser) => {
        let image = {
          "@type": "ImageObject",
          contentUrl: this.getImgURL(teaser.src),
          width: this.JSONcontent.teaserComponent.teasersImgWidth,
          height: this.JSONcontent.teaserComponent.teasersImgHeight,
        };
        images.push(image);
      });
      this.JSONcontent.suppliersComponent.suppliers?.forEach((logo) => {
        let image = {
          "@type": "ImageObject",
          contentUrl: this.getImgURL(logo.src),
          width: this.JSONcontent.suppliersComponent.suppliersImgWidth,
          height: this.JSONcontent.suppliersComponent.suppliersImgHeight,
        };
        images.push(image);
      });
      images.push({
        "@type": "ImageObject",
        contentUrl: this.getImgURL(this.JSONcontent.campaignComponent.logo.src),
        width: 65,
        height: 56,
      });
      images.push({
        "@type": "ImageObject",
        contentUrl: this.getImgURL(
          this.JSONcontent.applyTeaserComponent.teaserImg.src
        ),
        width: this.JSONcontent.applyTeaserComponent.teaserImg.width,
        height: this.JSONcontent.applyTeaserComponent.teaserImg.height,
      });
      this.jsonLdData[2].image = images;
    },
    async addJsonLdToHead() {
      await this.getFAQSchema();
      await this.getImgSchema();
      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.textContent = JSON.stringify(this.jsonLdData);
      document.head.appendChild(script);
    },
    removeJsonLdFromHead() {
      document.head.querySelectorAll("script").forEach((script) => {
        if (script.type === "application/ld+json") {
          script.remove();
        }
      });
    },
  },
  beforeDestroy() {
    this.removeJsonLdFromHead();
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  max-width: 1168px;
  padding-top: 0px;
}

.content {
  display: flex;
  flex-direction: column;
}

.full-width-background-wrap {
  background: #ffbc0d; // linear-gradient(#ffffff, #ffbc0d);
  width: 100vw;
  min-width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  margin-top: 0;
  &.bg-steps {
    background: #ffbc0d;
  }
  &.bg-campaign {
    background: #264f36;
  }
}

.inner-content {
  max-width: 1168px;
}

.scroll-down {
  color: #292929;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  width: auto;
  height: auto;
  text-transform: none;
  padding: 0px;
  letter-spacing: 0;

  &:hover,
  &:focus,
  &:focus-visible {
    &:before {
      opacity: 0;
    }
    .mdi-arrow-down:after {
      opacity: 0.08;
    }
  }

  .mdi-arrow-down {
    width: 32px;
    height: 32px;
    background: #ffbc0d;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    &:before {
      font-size: 20px;
    }
    &:after {
      transform: unset;
    }
  }
}

.delivery-service-logo {
  object-fit: contain;
}

.legal-lines {
  > span {
    display: block;
    font-size: 11px;
    line-height: 1.3;
    margin-bottom: 10px;
  }
}
</style>
