<template>
  <v-row
    no-gutters
    class="campaign-teaser-image pa-3 pa-md-0 pb-0 mb-n2 mb-md-0"
    justify="center"
    :class="{
      mobile: $vuetify.breakpoint.smAndDown,
      noBorder: $vuetify.breakpoint.smAndDown && noBorderOnMobile,
    }"
    ref="campaignContent"
  >
    <v-col cols="12" align="center" justify="center" class="d-flex flex-column">
      <!-- mobile -->
      <template v-if="$vuetify.breakpoint.smAndDown">
        <v-expansion-panels class="campaign-teaser-panels">
          <v-expansion-panel class="pb-8" :style="{ backgroundColor: bgColor }">
            <v-expansion-panel-header class="pa-0" @click="scrollToContent">
              <div
                class="d-flex align-center gap-4 justify-center w-100"
              >
                <p
                  class="big mb-0"
                  v-html="teaserText"
                  :style="{ color: textColor }"
                ></p>
                <img
                  v-if="campaignLogo.src"
                  width="64"
                  height="57"
                  :src="getImgURL(campaignLogo.src)"
                  :alt="campaignLogo.alt"
                  class="img-fluid"
                  loading="lazy"
                />
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <img
                class="img-fluid my-7"
                :width="imgWidth"
                :height="imgHeight"
                :src="imgSrc"
                :alt="altTag"
                :rel="preload ? 'preload' : ''"
              />
              <div class="d-flex flex-column align-center align-md-start">
                <h2 class="mb-4" :style="{ color: textColor }">
                  {{ headline }}
                </h2>
                <p
                  class="mb-4 text-center text-md-left"
                  :style="{ color: textColor }"
                >
                  {{ text }}
                </p>
                <a
                  :href="btnUrl"
                  class="mdButton mb-6"
                  :class="$vuetify.breakpoint.xs ? 'w-100 justify-center' : ''"
                >
                  {{ btnText }}
                </a>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <!-- desktop -->
      <template v-else>
        <img
          class="img-fluid"
          :width="imgWidth"
          :height="imgHeight"
          :src="imgSrc"
          :alt="altTag"
          :rel="preload ? 'preload' : ''"
        />
        <div
          v-if="withContentBox"
          class="contentBox"
          :class="{ mediumSize: $vuetify.breakpoint.md }"
        >
          <div
            class="contentBoxInner pt-6 pr-8 pb-6 pl-8"
            :style="{ backgroundColor: bgColor }"
          >
            <div class="d-flex flex-column align-center align-md-start">
              <h2 class="mb-5" :style="{ color: textColor }">{{ headline }}</h2>
              <h4
                v-if="!$vuetify.breakpoint.smAndDown"
                class="mb-5 text-center text-md-left"
                :style="{ color: textColor }"
              >
                {{ teaserText }}
              </h4>
              <p
                class="mb-5 text-center text-md-left"
                :style="{ color: textColor }"
              >
                {{ text }}
              </p>
              <a
                :href="btnUrl"
                class="mdButton mb-5"
                :style="{ color: textColor }"
              >
                {{ btnText }}
              </a>
            </div>
          </div>
        </div>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CampaignTeaser",
  props: [
    "imgSrc",
    "imgWidth",
    "imgHeight",
    "withContentBox",
    "altTag",
    "noBorderOnMobile",
    "preload",
    "headline",
    "text",
    "btnText",
    "btnUrl",
    "teaserText",
    "bgColor",
    "textColor",
    "campaignLogo",
  ],
  data: () => ({}),
  methods: {
    getImgURL(path) {
      return process.env.VUE_APP_ASSETS_URL + 'images/' + path;
    },
    scrollToContent($event) {
      if (
        !$event.target.parentElement.parentElement.classList.contains(
          "v-expansion-panel-header--active"
        )
      ) {
        this.$refs["campaignContent"].scrollIntoView({ behavior: "smooth" });
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign-teaser-image {
  width: 100%;
  position: relative;
}

:deep(.campaign-teaser-panels) {
  .v-expansion-panel-header {
    flex-wrap: wrap;
    position: static;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    bottom: 0px;
    left: calc(50% - 16px);

    margin: auto;
    justify-content: center;
    .v-icon {
      font-size: 32px;
      color: #ffffff !important;
    }
  }
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}

.contentBox {
  max-width: 436px;
  position: absolute;
  top: 65px;
  left: 50px;
  display: flex;
  align-items: center;

  &.mediumSize {
    top: 0;
    bottom: 0;
  }
}

.contentBoxInner {
  background: #ffffff;
}

/*MOBILE CSS*/
.campaign-teaser-image.mobile {
  border: 1px solid #cecece;
  border-radius: 0 0 4px 4px;

  .contentBox {
    max-width: none;
    position: static;
  }
}

.campaign-teaser-image.mobile.noBorder {
  border: 0;
}

//REPONSIVE LEGAL TEXT CSS
.campaign-teaser-image {
  position: relative;

  .col {
    position: relative;
  }

  .legal-text {
    text-align: left;
    position: absolute;
    bottom: 3px;
    left: 0px;
    font-size: 10px;
    line-height: 1;
    color: black;
    width: 100%;
    padding: 0px 10px;
    z-index: 200;
  }

  &.mobile .legal-text {
    position: relative;
    padding: 10px 10px 0px;
    bottom: 0px;
  }
}
</style>
