<template>
  <div class="delivery-service-search" v-if="Object.keys(JSONcontent).length && Object.keys(JSONsuppliers).length">
    <v-row no-gutters justify="center" align="center" ref="search">
      <v-col cols="12" align="left">
        <v-form
          class="plz-search-form"
          align="center"
          @submit.prevent="submit"
          ref="form"
        >
          <v-text-field
            v-model="zipCode"
            class="plz-search-input ma-0"
            :label="placeholder"
            solo
            :error-messages="
              inputFieldIsValid && zipCodeDoesntExist ? errorText : ''
            "
            :rules="numberRules"
          ></v-text-field>
          <v-btn class="submit-button" icon color="black" @click="submit" light>
            <v-icon v-if="!searchInProgress" color="#ffffff">
              mdi-arrow-right
            </v-icon>
            <v-progress-circular
              v-else
              style="height: 18px; width: 18px"
              indeterminate
              color="#ffffff"
            ></v-progress-circular>
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <!-- Result Boxes-->
    <CustomDialog
      v-model="dialog"
      :title="
        searchTriggered
          ? JSONcontent.supplierDialogTitle
          : JSONcontent.voteDialogTitle
      "
      :btnText="
        searchTriggered
          ? JSONcontent.supplierBtnText
          : voted
          ? JSONcontent.closeBtnText
          : JSONcontent.voteBtnText
      "
      :btnCentered="
        searchTriggered ? ($vuetify.breakpoint.xs ? true : false) : true
      "
      :btnDisabled="searchTriggered ? !selectedDeliveryService : false"
      :fullscreen="$vuetify.breakpoint.xs ? true : false"
      @click="
        searchTriggered
          ? confirmLinkAndRedirect(
              selectedDeliveryService,
              deliveryServiceContent(selectedDeliveryService).link
            )
          : voted
          ? ((dialog = false), (voted = false))
          : submitVote()
      "
    >
      <!-- suppliers -->
      <template v-if="searchTriggered">
        <v-row justify="center" ref="searchResult" no-gutters>
          <v-col
            v-for="(supplier, i) in searchResultSuppliers"
            :key="i"
            class="box-item py-2"
            cols="12"
          >
            <div
              class="box-content-wrapper d-flex align-start gap-3 pa-4"
              @click="selectDeliveryService(supplier.id)"
              :class="selectedDeliveryService === supplier.id ? 'selected' : ''"
            >
              <img
                class="img-fluid"
                :width="40"
                :height="40"
                :src="deliveryServiceContent(supplier.id).image"
                :alt="deliveryServiceContent(supplier.id).altTag"
                loading="lazy"
              />
              <div class="content d-flex flex-column gap-2">
                <h4 class="small">
                  {{ deliveryServiceContent(supplier.id).deliveryServiceName }}
                </h4>
                <p
                  class="small mb-0"
                  v-html="deliveryServiceContent(supplier.id).voucherHint"
                  v-if="deliveryServiceContent(supplier.id).voucherHint"
                ></p>

                <v-expansion-panels class="legal-panels">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="pa-0 mb-2" hide-actions>
                      <template v-slot:default="{ open }">
                        <div
                          class="legal-panel-header d-flex align-center gap-2"
                        >
                          <v-icon class="legal-panel-icon">
                            <template v-if="open">mdi-minus</template>
                            <template v-else>mdi-plus</template>
                          </v-icon>
                          <p class="small mb-0">
                            <sup>{{ i+1 }} </sup>{{ JSONcontent.voucherConditionsText }}
                          </p>
                        </div>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p class="mb-0">
                        <sub style="bottom: 0.5em">{{
                          deliveryServiceContent(supplier.id)
                            .legalLineHintNumber
                        }}</sub>
                        {{ deliveryServiceContent(supplier.id).legalLine }}
                      </p>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>

      <!-- no suppliers (vote) -->
      <template v-else>
        <v-row justify="center" no-gutters>
          <v-col cols="12" align="center">
            <img
              :src="getImgURL($vuetify.breakpoint.xs ? JSONcontent.teaserImg.srcMobile : JSONcontent.teaserImg.src)"            
              :width="$vuetify.breakpoint.xs ? JSONcontent.teaserImg.widthMobile : JSONcontent.teaserImg.width"
              :height="$vuetify.breakpoint.xs ? JSONcontent.teaserImg.heightMobile : JSONcontent.teaserImg.height"
              :alt="JSONcontent.teaserImg.alt"
              loading="lazy"
              class="img-fluid mb-6 mt-2"
            />
          </v-col>

          <!-- not voted yet -->
          <v-col cols="12" align="center" v-if="!voted">
            <h4 class="mb-3" align="center">
              {{ JSONcontent.notVotedTitle }}
            </h4>
            <p class="mb-2" align="center">
              {{ JSONcontent.notVotedText }}
            </p>
          </v-col>

          <!-- voted -->
          <v-col cols="12" align="center" v-else>
            <h4 class="mb-3" align="center">{{ JSONcontent.votedTitle }}</h4>
            <p class="mb-2" align="center" v-html="JSONcontent.votedText"></p>
          </v-col>
        </v-row>
      </template>
    </CustomDialog>
  </div>
  <!--Search Form-->
</template>

<script>
import axios from "axios";
import CustomDialog from "./CustomDialog.vue";

export default {
  name: "SearchComponent",
  props: ["placeholder", "errorText"],
  components: {
    CustomDialog,
  },
  data: (_this) => ({
    JSONcontent: _this.$root.JSONcontent.searchResultComponent ?? {},
    JSONsuppliers: _this.$root.JSONsuppliers ?? {},
    JSONhasCampaign: _this.$root.JSONhasCampaign ?? false,
    zipCode: "",
    suppliers: [],
    searchTriggered: false,
    formHasErrors: false,
    numberRules: [],
    inputFieldIsValid: false,
    searchInProgress: false,
    searchResultSuppliers: [],
    zipCodeErrorResponse: [],
    dialog: false,
    voted: false,
    selectedDeliveryService: "",
  }),
  methods: {
    searchZipCode(zipCode) {
      this.searchInProgress = true;
      axios
        .get(process.env.VUE_APP_API_URL + zipCode)
        .then((response) => {
          this.inputFieldIsValid = true;
          this.searchResultSuppliers = [];
          this.zipCodeErrorResponse = [];

          this.searchResultSuppliers = response.data.suppliers;
          this.zipCodeErrorResponse = response.data.errors.error;

          if (this.searchResultSuppliers.length < 1 && !this.zipCodeErrorResponse) {
            this.searchTriggered = false;
            this.dialog = true;
          }
          if (this.searchResultSuppliers.length > 0 && !this.zipCodeErrorResponse) {
            this.searchTriggered = true;
            this.dialog = true;
          }

          this.searchInProgress = false;
        })
        .catch((error) => {
          this.searchInProgress = false;
          this.zipCodeErrorResponse = error.message;
          console.log(error);
        });
    },
    deliveryServiceTrackingName(deliveryService) {
      let selectedDeliveryService = "";
      if (deliveryService && deliveryService === "LIEFERANDO") {
        selectedDeliveryService = "Lieferando";
      } else if (deliveryService && deliveryService === "UBEREATS") {
        selectedDeliveryService = "Uber Eats";
      } else if (deliveryService && deliveryService === "WOLT") {
        selectedDeliveryService = "Wolt";
      }
      return selectedDeliveryService;
    },
    deliveryServiceContent(deliveryService) {
      let showInitial = this.JSONhasCampaign; // show initial values

      let deliveryServiceName = "";
      let serviceLink = "";
      let imgSrc = "";
      let altTag = "";
      let voucherCode = "";
      let minimumOrderValue = "";
      let legalLineText = "";
      let legalLineHintNumber = "";
      let voucherHint = "";

      let suppliers = this.JSONsuppliers;

      suppliers.forEach((supplier) => {
        if (deliveryService && deliveryService === supplier.id) {
          deliveryServiceName = supplier.name;
          imgSrc = this.getImgURL(supplier.logo);
          altTag = supplier.alt;
          serviceLink = supplier.link;
          voucherCode = supplier.voucherCode;
          minimumOrderValue = showInitial ? '' : supplier.minimumOrderValue;
          legalLineText = showInitial ? supplier.legalLineTextInitial : supplier.legalLineText,
          legalLineHintNumber = supplier.legalLineHintNumber;
          voucherHint = showInitial ? supplier.voucherHintInitial : supplier.voucherHint;
        }
      });

      return {
        deliveryServiceName: deliveryServiceName,
        image: imgSrc,
        altTag: altTag,
        link: serviceLink,
        voucherCode: voucherCode,
        minimumOrderValue: minimumOrderValue,
        legalLineHintNumber: legalLineHintNumber,
        voucherHint: voucherHint,
        legalLine: legalLineText,
      };
    },

    submit() {
      this.inputFieldIsValid = false;
      this.numberRules = [
        (v) => !!v || "Pflichtfeld",
        (v) => v.length >= 5 || "Keine gültige Postleitzahl",
        (v) => v.length <= 5 || "Keine gültige Postleitzahl",
        (v) => /^\d+$/.test(v) || "Keine gültige Postleitzahl",
      ];

      let self = this;
      setTimeout(() => {
        if (self.$refs.form.validate()) {
          this.matomoTrackingEventSearchPostCode();
          this.searchTriggered = false;
          this.searchZipCode(this.zipCode);
        } else {
          return false;
        }
      });
    },
    selectDeliveryService(deliveryService) {
      this.selectedDeliveryService = deliveryService;
    },
    confirmLinkAndRedirect(deliveryService, link) {
      if (confirm(this.JSONcontent.textLinkConfirmationMessage) === true) {
        this.matomoTrackingEventDeviveryServiceClicked(deliveryService);
        window.location.href = link;
      } else {
        return false;
      }
    },
    matomoTrackingEventSearchPostCode() {
      window._paq.push([
        "trackEvent",
        "Website Modules",
        "Enter Postcode",
        this.zipCode,
      ]);
    },
    matomoTrackingEventDeviveryServiceClicked(deliveryService) {
      window._paq.push([
        "trackEvent",
        "Conversions",
        "Delivery Service",
        this.deliveryServiceTrackingName(deliveryService),
      ]);
    },
    getImgURL(path) {
      return process.env.VUE_APP_ASSETS_URL + 'images/' + path;
    },
    matomoTrackingEventVote() {
      window._paq.push([
        "trackEvent",
        "Conversions",
        "Vote for Service",
        this.zipCodeForVoting,
      ]);
    },
    submitVote() {
      this.matomoTrackingEventVote();
      axios
        .post(process.env.VUE_APP_API_URL + "vote", this.zipCodeForVoting, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.voted = true;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error(error);
          this.voted = false;
        });
    },
  },
  computed: {
    zipCodeDoesntExist() {
      if (this.zipCodeErrorResponse) {
        return this.zipCodeErrorResponse.length > 0;
      } else {
        return false;
      }
    }
  },
  watch: {
    zipCode() {
      this.numberRules = [];
      this.inputFieldIsValid = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.plz-search-form {
  position: relative;
  @media (min-width: 960px) {
    max-width: 480px;
  }
}

.submit-button {
  position: absolute;
  top: 2px;
  right: 2px;
  background: #264f36;
  border-radius: 4px;
  width: 44px;
  height: 44px;
}

/* ######## SEARCH RESULT CSS ########*/
.box-content-wrapper {
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  .img-fluid {
    min-width: 40px;
  }
  &.selected {
    border: 2px solid #6f6f6f;
    margin: -1px;
  }
}

.legal-panels {
  .v-expansion-panel-header {
    flex-direction: row-reverse;
    min-height: unset;
    .legal-panel-icon {
      &:before {
        font-size: 16px;
        color: #292929;
      }
    }
  }
  :deep(.v-expansion-panel-content__wrap) {
    padding: 0;
  }
  *:not(sub):not(sup) {
    font-size: 11px;
    line-height: 16px;
  }
}
</style>

<style lang="scss">
/*UNSCOPED INPUT FIELD CSS*/
.delivery-service-search {
  .v-input__slot {
    box-shadow: none !important;
    font-family: "Speedee";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    color: #6f6f6f;
    border: 2px solid white;
    border-radius: 4px;
  }

  .v-label {
    color: #6f6f6f !important;
  }

  .v-ripple__container {
    opacity: 0 !important;
  }

  .v-messages__wrapper {
    text-align: left;
  }

  .v-messages__message {
    height: auto;
    line-height: 22px;
    font-size: 16px;
  }

  .voucherCode {
    font-size: 20px;
  }

  .rabatt-text {
    font-size: 24px;
  }
}
</style>
