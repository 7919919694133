<template>
  <v-row>
    <v-col
      justify="center"
      class="accordion-component"
      :class="$vuetify.breakpoint.mdAndDown ? 'mobile' : ''"
    >
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(faq, i) in accordionContent" :key="i">
          <v-expansion-panel-header
            class="heading"
            @click="matomoTrackingEventFaqClicked(faq.header)"
            ><span v-html="faq.header"></span
          ></v-expansion-panel-header>
          <v-expansion-panel-content>
            <p v-html="faq.content" style="max-width: 1000px"></p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AccordionComponent",
  props: ["accordionContent"],
  data: () => ({}),
  methods: {
    matomoTrackingEventFaqClicked(question) {
      window._paq.push([
        "trackEvent",
        "Website Modules",
        "FAQ- Accordion",
        question,
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-component {
  .v-expansion-panels {
    padding-left: 20px;
  }
  .heading {
    font-family: "Speedee";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #292929;
    padding: 32px 26px 32px 20px;
    :deep(.v-expansion-panel-header__icon) {
      .v-icon {
        font-size: 32px;
        color: #DB0007;
        margin-left: 8px;
      }
    }
  }
}
</style>

<style>
.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-expansion-panel::after {
  border-color: #cecece !important;
}

.v-expansion-panel-content__wrap {
  padding: 0px 26px 44px 0px;
}

.v-expansion-panel-content__wrap p {
  line-height: 24px;
}

.accordion-component .v-expansion-panel:first-child {
  border-top: 1px solid #cecece;
  border-radius: unset;
}

.accordion-component .v-expansion-panel:last-child {
  border-bottom: unset;
}
</style>
