<template>
  <v-row justify="center" class="step-image-text">
    <v-col
      v-for="(stepItem, i) in stepContent"
      :key="i"
      cols="12"
      :md="dynamicCols"
      sm="6"
    >
      <div
        class="step-content-wrapper"
        :class="$vuetify.breakpoint.xs ? 'mobile' : ''"
      >
        <img
          class="img-fluid mb-6"
          :width="imgWidth"
          :height="imgHeight"
          :src="
            getImgURL(
              $vuetify.breakpoint.xs ? stepItem.srcMobile : stepItem.src
            )
          "
          :alt="stepItem.alt"
          loading="lazy"
        />
        <!-- title-->
        <h3 class="mb-4" v-html="stepItem.title"></h3>
        <!-- text-->
        <p v-if="stepItem.text" v-html="stepItem.text" class="mb-0"></p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "stepesImageText",
  props: ["stepContent", "imgWidth", "imgHeight"],
  data: () => ({}),
  methods: {
    getImgURL(path) {
      return process.env.VUE_APP_ASSETS_URL + 'images/' + path;
    },
  },
  computed: {
    dynamicCols() {
      if (this.stepContent.length <= 2) {
        return 6;
      } else if (this.stepContent.length <= 3) {
        return 4;
      } else {
        return 3;
      }
    },
  },
};
</script>
